/* Launcher.module.css */
.launcherPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.launcherSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}


.introText {
  color: #41a85f;
  font-size: 24px;
  margin: 0;
  line-height: 1.4;
  font-weight: bold;
}

.sectionTitle {
  color: #d14841;
  font-size: 22px;
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
}

.featureGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.featureItem {
  background-color: #212127;
  padding: 15px;
  border-radius: 6px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.featureItem:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}



.featureItem p {
  margin: 0;
  line-height: 1.4;
}

.screenshotGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.screenshotItem {
    cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.screenshotItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.screenshotItem img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
}

.priceLink {
  display: inline-block;
  color: #afe54f;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.priceLink:hover {
  color: #8fb83f;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.modalContent {
  position: relative;
  max-width: 90%;
  max-height: 90vh;
}

.modalContent img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.closeButton {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  padding: 5px 10px;
}

.closeButton:hover {
  color: #afe54f;
}

/* Responsive Design */
@media (min-width: 768px) {
  .screenshotGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .screenshotGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* Module.module.css */
.modulePageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.moduleSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.moduleTitle {
  color: #afe54f;
  font-size: 24px;
  margin: 0 0 20px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
}

.moduleGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.moduleItem {
  background-color: #212127;
  border-radius: 6px;
  padding: 15px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.moduleItem:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.moduleItem h3 {
  color: #afe54f;
  margin: 0 0 10px 0;
  font-size: 18px;
}

.moduleItem p {
  margin: 0;
  line-height: 1.5;
  opacity: 0.9;
}

.moduleNote {
  margin-top: 20px;
  padding: 15px;
  background-color: #212127;
  border-radius: 6px;
  border-left: 4px solid #afe54f;
}

.footnote {
  margin-top: 30px;
  color: #afe54f;
  font-style: italic;
  text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .moduleGrid {
    grid-template-columns: 1fr;
  }
  
  .moduleItem {
    margin-bottom: 15px;
  }
}
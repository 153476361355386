/* Information.module.css */
.infoPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.infoSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.infoSection:hover {
    border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.infoTitle {
  color: #afe54f;
  font-size: 20px;
  margin: 0 0 15px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
}

.moduleList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  columns: 2;
  column-gap: 40px;
}

.moduleList li {
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
  break-inside: avoid;
  line-height: 1.5;
}

.moduleList li::before {
  content: "•";
  color: #afe54f;
  position: absolute;
  left: 0;
}

.infoSection p {
  margin: 10px 0;
  line-height: 1.6;
}

/* For smaller screens */
@media (max-width: 768px) {
  .moduleList ul {
    columns: 1;
  }
}
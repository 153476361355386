.mainContainer {
  width: 1140px;
  margin: 0 auto;
}

.contentContainer {
  display: flex;
  gap: 20px;
}

.mainContent {
  flex: 1;
  max-width: 847px;
border-radius: 4px;
}

.sidebar {
  width: 262px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.discordContainer{
  margin-left: 10px;
}
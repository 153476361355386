/* Faq.module.css */
.faqPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.faqIntro {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid #afe54f;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

.faqContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faqItem {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.faqItem:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.question {
  color: #afe54f;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.questionMark {
  color: #afe54f;
  font-weight: bold;
  margin-right: 8px;
  min-width: 20px;
}

.answer {
  color: #ddd;
  line-height: 1.6;
  display: flex;
  align-items: flex-start;
}

.answerMark {
  color: #d14841;
  font-weight: bold;
  margin-right: 8px;
  min-width: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faqItem {
    padding: 15px;
  }

  .question,
  .answer {
    font-size: 14px;
  }
}
.pricePageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.pricePageWarning {
  background-color: rgba(184, 49, 47, 0.1);
  border-left: 4px solid rgb(184, 49, 47);
  color: rgb(184, 49, 47);
  font-weight: bold;
  margin-bottom: 30px;
  padding: 15px 20px;
  border-radius: 4px;
}

.licensesTitle {
  color: #afe54f;
  font-size: 24px;
  margin: 30px 0 20px 0;
  padding: 0 20px;
}

.pricePageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licenseItem {
  display: flex;
  align-items: center;
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.licenseItem:hover {
  background-color: #212127;
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.pricePageLicenseType {
  flex: 1;
  min-width: 150px;
}

.pricePageLicenseType h3 {
  margin: 0;
  font-size: 18px;
  color: #afe54f;
}

.pricePageLicenseExtra {
  flex: 2;
  padding: 0 20px;
}

.pricePageLicenseExtra p {
  margin: 5px 0;
  opacity: 0.9;
}

.pricePageLicensePrice {
  flex: 1;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  min-width: 100px;
  color: #afe54f;
}

.pricePageSection {
  background-color: #1b1b1c;
  margin-top: 10px;
  border-radius: 8px;
  padding: 20px;
}

.pricePageSection h2 {
  color: #afe54f;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.pricePageSection h2:first-child {
  margin-top: 0;
}

.pricePageSection p {
  margin: 8px 0;
  padding-left: 15px;
  opacity: 0.9;
}

/* Responsive design */
@media (max-width: 768px) {
  .licenseItem {
    flex-direction: column;
    text-align: center;
  }
  
  .pricePageLicenseExtra {
    padding: 15px 0;
  }
  
  .pricePageLicensePrice {
    text-align: center;
  }
}
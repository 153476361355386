/* Article.module.css */
.articleContainer {
  background-color: #1b1b1c;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.articleContainer:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.articleTitle {
  color: #afe54f;
  font-size: 18px;
  margin: 0 0 15px 0;
  font-weight: bold;
}

.articleContent {
  color: #ddd;
}

.articleContent p {
  margin: 8px 0;
  line-height: 1.0;
  opacity: 0.9;
}

.articleContent button {
  color: #afe54f;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 8px 0;
  margin-top: 10px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.articleContent button:hover {
  color: #8fb83f;
  transform: translateX(5px);
}
.app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  min-height: 100vh;
}

.main-logo {
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.header-menu-container {
  margin-top: 24px;
  background-color: #212127;
  border-radius: 4px;
  max-height: 51px;
}

.header-menu {
  padding-left: 14px;
  text-decoration: none;
  display: flex;
  justify-content: space-between; /* This spreads the left and right sections */
  align-items: center;
}

.header-menu-left {
  display: flex;
  align-items: center;
}

.header-menu-right {
  margin-right: 14px;
}

.headerButton {
  font-size: 14px;
  padding: 15px;
  color: #ddd;
  text-decoration: none;
  line-height: 20px;
}

.headerButton:hover {
  cursor: pointer;
  background-color: #3f3f4b;
  transition: 0.5s;
}

.header-menu img {
  max-width: 20px;
  max-height: 20px;
  padding: 15px;
  line-height: 20px;
}

/* New Pay Button Styles */
.payButton {
  background-color: #afe54f;
  color: #212127;
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 14px;
  line-height: 20px;
}

.payButton:hover {
  background-color: #8fb83f;
  transform: translateY(-2px);
}
.footerContainer {
  text-align: center;
  margin-top: 24px;
  background-color: #212127;
  border-radius: 12px;
  font-size: 14px;
  padding: 1px;
  
  color: #ddd;
  line-height: 20px;
}

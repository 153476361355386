/* Blacklist.module.css */
.blacklistPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.blacklistHeader {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid #d14841;
}

.blacklistHeader h2 {
  color: #d14841;
  font-size: 18px;
  margin: 0;
  font-weight: bold;
}

.blacklistContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blacklistItem {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
}

.blacklistItem::before {
  content: "⚠️";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  opacity: 0.2;
}

.blacklistItem:hover {
  border-color: #d14841;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(209, 72, 65, 0.1);
}

.blacklistName {
  color: #41a85f;
  font-size: 18px;
  margin: 0 0 15px 0;
  font-weight: bold;
}

.blacklistDetails {
  color: #ddd;
}

.blacklistDetails p {
  margin: 8px 0;
  line-height: 1.6;
}

.blacklistDetails strong {
  color: #afe54f;
  margin-right: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .blacklistItem {
    padding: 15px;
  }

  .blacklistName {
    font-size: 16px;
  }

  .blacklistDetails {
    font-size: 14px;
  }
}
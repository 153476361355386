/* Pay.module.css */
.payPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.warningSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid #d14841;
  transition: all 0.3s ease;
}

.warningSection h2 {
  color: #d14841;
  font-size: 18px;
  margin: 0 0 15px 0;
  font-weight: bold;
}

.warningSection p {
  margin: 8px 0;
  line-height: 1.6;
}

.paymentSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.paymentSection:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}

.payeerContainer {
  margin-bottom: 30px;
  padding: 10px;
  background-color: #212127;
  border-radius: 8px;
  display: inline-block;
}

.payeerContainer img {
  max-width: 320px;
  height: auto;
  transition: transform 0.3s ease;
}

.payeerContainer:hover img {
  transform: scale(1.02);
}

.anypayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.anypayButton {
  background-color: #212127;
  padding: 10px;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.anypayButton:hover {
  transform: translateX(5px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .paymentSection {
    padding: 15px;
  }

  .payeerContainer img {
    max-width: 100%;
  }

  .anypayButton iframe {
    max-width: 100%;
  }
}
/* Contact.module.css */
.contactPageWrapper {
  color: #ddd;
  padding-top: 20px;
}

.warningSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border-left: 4px solid rgb(184, 49, 47);
  transition: all 0.3s ease;
}

.warningSection p {
  color: rgb(184, 49, 47);
  font-weight: bold;
  margin: 0;
  line-height: 1.5;
}

.contactSection {
  background-color: #1b1b1c;
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s ease;
}

.contactItem {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #212127;
  border-radius: 6px;
  transition: all 0.3s ease;
    border: 1px solid transparent;
}

.contactItem:hover {
  border-color: #afe54f;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(175, 229, 79, 0.1);
}


.contactItem:last-child {
  margin-bottom: 0;
}

.contactItem h3 {
  color: #afe54f;
  margin: 0 0 10px 0;
  font-size: 16px;
}

.contactItem p {
  margin: 0;
  line-height: 1.6;
}

.contactItem a {
  color: #afe54f;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contactItem a:hover {
  color: #8fb83f;
}

.note {
  color: rgb(235, 107, 86);
  margin-left: 8px;
  font-size: 14px;
}

.discordImage {
  margin-top: 20px;
  text-align: center;
}

.discordImage img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contactItem {
    padding: 12px;
  }

  .contactItem h3 {
    font-size: 14px;
  }

  .note {
    display: block;
    margin-left: 0;
    margin-top: 5px;
  }
}
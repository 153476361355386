/* News.module.css */
.newsContainer {
  width: 100%;
}

.newsHeaderContainer {
  width: 100%;
  height: 40px;
  background-color: #212127;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  border-left: 4px solid #afe54f;
}

.newsHeaderContainer p {
  color: #ddd;
  font-size: 16px;
  padding: 0 20px;
  font-weight: bold;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 30px 0;
  padding: 10px;
}

.paginationButton {
  background-color: #212127;
  border: 1px solid transparent;
  color: #ddd;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  min-width: 35px;
  transition: all 0.3s ease;
}

.paginationButton:hover {
  background-color: #3f3f4b;
  border-color: #afe54f;
  transform: translateY(-2px);
}

.activePage {
  background-color: #afe54f;
  color: #212127;
  font-weight: bold;
}

.activePage:hover {
  background-color: #8fb83f;
  border-color: transparent;
}

.paginationDots {
  color: #ddd;
  padding: 0 4px;
}
.resourcesContainer {
  background-color: #1b1b1c;
  border-radius: 12px;
  width: 100%;
  margin-top: 24px;
  margin-left: 10px;
}

.resourcesTitle {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin: 0px;
  padding: 0px;
  background-color: #212127;
}

.resourcesTitle p {
  color: #ddd;
  font-size: 14px;
  margin: 0px;
  padding: 12px 15px 12px 15px;
}

.resourcesLinks a {
margin-bottom: 10px;
}

.resourcesLinks a {
  display: flex;
  align-items: center;
  color: #afe54f;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 21px;
  margin-top: 12px;
}

.resourcesIcon {
  filter: invert(100%) sepia(57%) saturate(2076%) hue-rotate(21deg)
    brightness(91%) contrast(91%);
  margin-left: 15px;
  max-width: 14px;
  height: 16px;
  /* Remove padding-top since we're using flexbox alignment */
  padding-top: 0;
  padding-right: 4px;
}
